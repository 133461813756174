import React from 'react';
import Img from 'gatsby-image';

const Item = ({ title, image }) => (
  <div className="partner-item">
    {/* <Img fluid={image.childImageSharp.fluid} title={title} /> */}
    <img src={image.childImageSharp.fluid.src} title={title} alt={title} />
  </div>
);

export default Item;
