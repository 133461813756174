import React from 'react';
import Icon from '../Icon';

const ContactDetails = () => (
  <>
    <ul className="list-unstyled text-small contact-details">
      <li>
        <Icon icon={['fal', 'location-arrow']} />
        <span>PO Box 5169, Murray Bridge South, 5253, South Australia</span>
      </li>
      <li>
        <Icon icon={['fal', 'phone']} flip="horizontal" />
        <span>
          <a href="tel:+610885489262">+61 08 8548 9262</a>
        </span>
      </li>

      <li>
        <Icon icon={['fal', 'envelope']} />
        <span>
          <strong>Info</strong>
          <a href="mailto:info@technocraft.aero">info@technocraft.aero</a>
        </span>
      </li>

      <li>
        <Icon icon={['fal', 'envelope']} />
        <span>
          <strong>Engineering</strong>
          <a href="mailto:rob@technocraft.aero">rob@technocraft.aero</a>
        </span>
      </li>

      <li>
        <a
          href="https://www.linkedin.com/company/technocraft-systems-pty-ltd/about/"
          title="LinkedIn"
          alt="LinkedIn"
        >
          <Icon icon={['fab', 'linkedin-in']} size="3x" />
        </a>
      </li>
    </ul>
  </>
);

export default ContactDetails;
