import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { Row, Col } from 'reactstrap';

import PartnerItem from './Item';

const Bar = () => (
  <StaticQuery
    query={graphql`
      query PartnersAndAwards {
        brandSA: file(relativePath: { eq: "Brand_SouthAust1_RGB.png" }) {
          childImageSharp {
            fluid(maxHeight: 100, grayscale: true) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        indra: file(relativePath: { eq: "indra_logo.png" }) {
          childImageSharp {
            fluid(maxHeight: 100, grayscale: true) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        regionalAwards2014: file(
          relativePath: { eq: "2014WinnerEmail.Innovation.jpg" }
        ) {
          childImageSharp {
            fluid(maxHeight: 100, grayscale: true) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => (
      <Row className="partner-bar py-3 text-center justify-content-center align-items-center">
        <Col>
          <PartnerItem image={data.brandSA} title="Proudly South Australian" />
        </Col>
        <Col>
          <PartnerItem
            image={data.regionalAwards2014}
            title="2014 South Australian Regional Innovation Award Winner"
          />
        </Col>
        <Col>
          <PartnerItem image={data.indra} title="Indra" />
        </Col>
      </Row>
    )}
  />
);

export default Bar;
