import React from 'react';
// import { Link } from 'gatsby';
import { Link } from 'react-scroll';
import {
  Container,
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  Row,
} from 'reactstrap';

import Icon from './Icon';
import logo from '../assets/images/logo-dark-text.svg';

export default class Header extends React.Component {
  constructor(props) {
    super(props);

    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.handleScroll = this.handleScroll.bind(this);

    this.state = {
      collapsed: true,
      scrolled: false,
    };
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll(event) {
    const navbarHeight = 60;

    if (window.pageYOffset > navbarHeight) {
      this.setState({ scrolled: true });
    }

    if (window.pageYOffset < navbarHeight) {
      this.setState({ scrolled: false });
    }
  }

  toggleNavbar() {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  }

  render() {
    return (
      <>
        {/* <div className="header-top-area bg-navy text-light">
          <Container>
            <Row>
              <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                <div className="header-call text-center text-md-left">
                  <span className="icon">
                    <Icon icon={['fal', 'phone']} flip="horizontal" />
                  </span>
                  <span>
                    <a href="tel:+610885489262">+61 08 8548 9262</a>
                  </span>
                </div>
              </div>
            </Row>
          </Container>
        </div> */}
        <Navbar expand="md" light fixed="top" className={this.state.scrolled ? 'scrolled' : ''}>
          <Container>
            <NavbarBrand href="/" className="mr-auto">
              <img src={logo} width="180" alt="Technocraft Logo" />
            </NavbarBrand>
            <NavbarToggler
              onClick={this.toggleNavbar}
              className="mr-2"
              aria-label="Toggle Navbar"
            />
            <Collapse isOpen={!this.state.collapsed} navbar>
              <Nav className="ml-auto" navbar>
                {/* <NavItem>
                  <Link
                    to="/"
                    className="nav-link"
                    activeClassName="active"
                    exact
                  >
                    Home
                  </Link>
                </NavItem> */}
                <NavItem>
                  <Link to="manufacturing" spy smooth hashSpy offset={-90} className="nav-link">
                    Manufacturing
                  </Link>
                </NavItem>

                <NavItem>
                  <Link to="design" spy smooth hashSpy offset={-90} className="nav-link">
                    Design
                  </Link>
                </NavItem>

                <NavItem>
                  <Link to="management" spy smooth hashSpy offset={-90} className="nav-link">
                    Management
                  </Link>
                </NavItem>

                <NavItem>
                  <Link to="engineering" spy smooth hashSpy offset={-90} className="nav-link">
                    Engineering
                  </Link>
                </NavItem>

                <NavItem>
                  <Link to="contact" spy smooth hashSpy offset={-90} className="nav-link">
                    Contact
                  </Link>
                </NavItem>
                {/* <NavItem>
                <Link to="/about" className="nav-link" activeClassName="active">
                  About
                </Link>
              </NavItem> */}
              </Nav>
            </Collapse>
          </Container>
        </Navbar>
      </>
    );
  }
}
