import React from 'react';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faLocationArrow,
  faPhone,
  faEnvelope,
  faBolt,
  faCheck,
  faDraftingCompass,
  faArrowRight,
  faTruckContainer,
  faIndustryAlt,
  faClipboardList,
  faBezierCurve,
} from '@fortawesome/pro-light-svg-icons';
import { faLinkedin, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';

library.add(
  faPhone,
  faEnvelope,
  faLocationArrow,
  faBolt,
  faCheck,
  faDraftingCompass,
  faArrowRight,
  faTruckContainer,
  faIndustryAlt,
  faClipboardList,
  faBezierCurve,
  faLinkedin,
  faLinkedinIn
);

const Icon = ({ ...rest }) => <FontAwesomeIcon {...rest} />;

export default Icon;
