import React from 'react';
import { CardBody } from 'reactstrap';

const List = ({ children }) => (
  <CardBody>
    <ul className="list-inline list-unstyled">{children}</ul>
  </CardBody>
);

export default List;
