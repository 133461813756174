import React from 'react';
import { CardBody, CardText } from 'reactstrap';

const Blurb = ({ children }) => (
  <CardBody>
    <CardText tag="div">{children}</CardText>
  </CardBody>
);

export default Blurb;
