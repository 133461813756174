import React from 'react';
import Img from 'gatsby-image';
import { Section } from '../Section';

const WwdSection = ({ children, ...rest }) => (
  <Section className="what-we-do" {...rest}>
    {children}
  </Section>
);

export default WwdSection;
