import React from 'react';
import { Container, Row } from 'reactstrap';
import classNames from 'classnames';

const Section = ({
  children,
  bg,
  color,
  className,
  contained,
  center,
  row,
  ...rest
}) => {
  const classes = classNames([
    className,
    { [`text-${color}`]: color },
    { [`bg-${bg}`]: bg },
    { 'text-center': center },
  ]);

  return (
    <section className={classes} {...rest}>
      {contained ? (
        <Container>
          <ConditionalRow row={row}>{children}</ConditionalRow>
        </Container>
      ) : (
        children
      )}
    </section>
  );
};

const ConditionalRow = ({ row, children }) =>
  row ? <Row>{children}</Row> : children;

Section.defaultProps = {
  className: 'py-5',
  contained: true,
  center: false,
  row: false,
};

export default Section;
