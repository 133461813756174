import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { ContactDetails } from './Contact';

const Footer = () => (
  <footer className="align-self-end">
    <div className="pre-footer py-5 text-white">
      <Container>
        <Row>
          <Col>
            <h3>About</h3>
            <p>
              Technocraft Systems Pty Ltd was established in Australia in 2004. We concentrate on
              providing Specialist Ground Systems Engineering and Design of Integration Solutions to
              manufacturers and customers of Air Traffic Management, Navigation Aids and
              Communication Electronics.
            </p>
          </Col>
          <Col>
            <h3>Contact</h3>
            <ContactDetails />
          </Col>
        </Row>
      </Container>
    </div>
    <div className="footer-bar py-3 text-center">
      <Container className="small">
        &copy; Technocraft Systems Pty Ltd &mdash; ABN: 27 108 750 492
        {/* &mdash; Website by{' '}
        <a href="https://northxsouth.co" className="text-muted">
          NorthxSouth.co
        </a> */}
      </Container>
    </div>
  </footer>
);

export default Footer;
