import React from 'react';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';

import Header from './Header';
import Footer from './Footer';
import '../styles/stylesheet.scss';

const Layout = ({ children, data }) => (
  <div className="gatsby-container d-flex flex-wrap flex-row">
    <Helmet title={data.site.siteMetadata.title}>
      <html lang="en" />
      <meta name="og:type" content="website" />
      <meta name="og:site_name" content={data.site.siteMetadata.title} />
      <meta name="description" content={data.site.siteMetadata.description} />
    </Helmet>
    <Header siteTitle={data.site.siteMetadata.title} />
    <div className="page-content">{children}</div>
    <Footer />
  </div>
);

const LayoutWithQuery = ({ children }) => (
  <StaticQuery
    query={graphql`
      query DefaultLayoutQuery {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `}
    render={data => <Layout data={data}>{children}</Layout>}
  />
);

export default LayoutWithQuery;
