import React from 'react';
import { Link, graphql } from 'gatsby';
import { Container, Col, Card, Row } from 'reactstrap';
import Img from 'gatsby-image';
// import banner from '../assets/counterpoiseDusk.jpg'
import Layout from '../components/Layout';
import { WhatWeDo, WhatWeDoItem, WhatWeDoTitle, WhatWeDoList, WhatWeDoBlurb } from '../components/WhatWeDo';
import { ContactDetails, ContactForm } from '../components/Contact';
import Icon from '../components/Icon';
import { PartnerBar } from '../components/PartnerBar';
import { Section } from '../components/Section';

const IndexPage = ({ data }) => (
  <Layout>
    <div className="banner d-flex flex-wrap justify-content-center align-items-md-center">
      <h1 className="banner-header">
        {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
        Quality Engineering <span>with</span> Uncommon&nbsp;Sense
      </h1>
      <Img fluid={data.counterpoiseDusk.childImageSharp.fluid} className="banner-image w-100 h-100" />
    </div>

    <Section id="blurb">
      <div className="wrap">
        <h2 className="text-center display-4">Technocraft Systems</h2>
        <p className="text-center">
          Providing specialist ground systems engineering and design of integrated solutions for air traffic
          management, navigation aids, and communication electronics.
        </p>
      </div>
    </Section>

    <WhatWeDo id="manufacturing">
      <Row className="justify-content-center">
        <Col md="9">
          <Card>
            <WhatWeDoTitle>
              <span className="title-icon">
                <Icon icon={['fal', 'industry-alt']} />
              </span>
              Manufacturing
            </WhatWeDoTitle>
            <WhatWeDoBlurb>
              <p>
                Specialising in the fabrication of Cyclone-proof DVOR Counterpoises, Radar Towers and
                Structures developed in our substantial steel engineering and electronic integration workshop
                facilities in South Australia.
              </p>
              <p>
                Our TCS-300 line of fully engineered, hot-dip halvanised, cyclone-proof is a mature product
                installed in many countries around the world, is rated to 300km/h and features a choice of
                access systems and surface mesh covering options for your needs.
              </p>
            </WhatWeDoBlurb>
            <WhatWeDoList>
              <WhatWeDoItem>Steel Structures</WhatWeDoItem>
              <WhatWeDoItem>DVOR Counterpoise</WhatWeDoItem>
              <WhatWeDoItem>Tailored NAVAID Shelters</WhatWeDoItem>
              <WhatWeDoItem>Transportable Systems</WhatWeDoItem>
              <WhatWeDoItem>Your design or ours</WhatWeDoItem>
            </WhatWeDoList>
          </Card>
        </Col>
      </Row>
    </WhatWeDo>

    <WhatWeDo id="design">
      <Row className="justify-content-center">
        <Col md="9">
          <Card>
            <WhatWeDoTitle>
              <span className="title-icon">
                <Icon icon={['fal', 'bezier-curve']} />
              </span>
              Design
            </WhatWeDoTitle>

            <WhatWeDoBlurb>
              <p>
                Technocraft has a proud and successful track record in the design of specialist transportable
                and rapid deployment systems.
              </p>
              <p>
                Our uniquely designed, transportable solutions are ideally suited to any of your Radar, Radio
                and VSAT, DVOR / DME, ILS, Power Generation, NDB / DME, MET, or ADS-B needs and are a perfect
                solution for deployment in areas that are remote and difficult access, environmentally
                sensitive, or part a disaster recovery effort. Our transportable systems are in successfully
                operating in all environments from the deserts of the world to equatorial islands.
              </p>
            </WhatWeDoBlurb>

            <WhatWeDoList>
              <WhatWeDoItem>Power Systems</WhatWeDoItem>
              <WhatWeDoItem>Modular & Mobile Rapid Deployment Systems</WhatWeDoItem>
              <WhatWeDoItem>Custom Specialist Systems</WhatWeDoItem>
            </WhatWeDoList>
          </Card>
        </Col>
      </Row>
    </WhatWeDo>

    <WhatWeDo id="management">
      <Row className="justify-content-center">
        <Col md="9">
          <Card>
            <WhatWeDoTitle>
              <span className="title-icon">
                <Icon icon={['fal', 'clipboard-list']} />
              </span>
              Management
            </WhatWeDoTitle>

            <WhatWeDoBlurb>
              <p>
                Our Mechanical and Electronics Technical Managers with decades of experience have worked
                internationally and excel in all site conditions.
              </p>
              <p>
                Our planning process considers these conditions when evaluating proposals and aims to reduce
                the risk in the field through careful and intelligent preparation in the factory and on-site.
              </p>
            </WhatWeDoBlurb>

            <WhatWeDoList>
              <WhatWeDoItem>Project Management</WhatWeDoItem>
              <WhatWeDoItem>Site Management</WhatWeDoItem>
              <WhatWeDoItem>Green field or Partial Project</WhatWeDoItem>
              <WhatWeDoItem>Management of Subcontracts</WhatWeDoItem>
              <WhatWeDoItem>Small or large projects1</WhatWeDoItem>
            </WhatWeDoList>
          </Card>
        </Col>
      </Row>
    </WhatWeDo>

    <WhatWeDo id="engineering">
      <Row className="justify-content-center">
        <Col md="9">
          <Card>
            <WhatWeDoTitle>
              <span className="title-icon">
                <Icon icon={['fal', 'drafting-compass']} />
              </span>
              Engineering
            </WhatWeDoTitle>
            <WhatWeDoList>
              <WhatWeDoItem>Commissioning, Installation, & Maintenance</WhatWeDoItem>
              <WhatWeDoItem>Complete Airports</WhatWeDoItem>
              <WhatWeDoItem>Navaids, ILS, ATC Tower, Radio, and Power Generation</WhatWeDoItem>
              <WhatWeDoItem>Site Surveys and Audits</WhatWeDoItem>
            </WhatWeDoList>
          </Card>
        </Col>
      </Row>
    </WhatWeDo>

    <Section bg="white" className="py-5 mt-5">
      <PartnerBar />
    </Section>

    <Section row id="contact">
      <Col xs={12} className="text-center">
        <h3 className="display-4 mb-4">Get in touch</h3>
      </Col>
      <Col>
        {/* <h2>Contact Details</h2> */}
        <ContactDetails />
      </Col>
      <Col md={7}>
        {/* <h2>Contact Form</h2> */}
        <ContactForm />
      </Col>
    </Section>
  </Layout>
);

export const query = graphql`
  query HomePageQuery {
    counterpoiseDusk: file(relativePath: { eq: "counterpoise-dusk.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default IndexPage;
