import React from 'react';
import Icon from '../Icon';

const Item = ({ children, ...rest }) => (
  <li className="list-inline-item" {...rest}>
    <span className="icon">
      <Icon icon={['fal', 'check']} />
    </span>
    {children}
  </li>
);

export default Item;
