import React from 'react';
import { Col, Button, Form, FormGroup, Input, FormText, Label } from 'reactstrap';

const ContactForm = () => (
  <>
    <form data-netlify="true" name="contact" method="post" netlify-honeypot="username">
      <input type="hidden" name="form-name" value="contact" />
      <p className="d-none">
        <Label>
          Don’t fill this out if you're human
          <input name="username" />
        </Label>
      </p>
      <FormGroup row>
        <Col md={6}>
          <Label for="name" hidden>
            Your Name
          </Label>
          <Input type="name" name="name" id="name" placeholder="Your Name" />
        </Col>
        <Col md={6}>
          <Label for="email" hidden>
            Your email
          </Label>
          <Input type="email" name="email" id="email" placeholder="Your Email" />
        </Col>
      </FormGroup>
      <FormGroup>
        <Label for="subject" hidden>
          Subject
        </Label>
        <Input type="text" name="subject" id="subject" placeholder="Subject" />
      </FormGroup>
      <FormGroup>
        <Label for="message" hidden>
          Your Message
        </Label>
        <Input type="textarea" name="message" id="message" placeholder="Message" />
      </FormGroup>
      <div data-netlify-recaptcha="true" />
      <Button size="lg" color="primary">
        Send Message
      </Button>
    </form>
  </>
);

export default ContactForm;
